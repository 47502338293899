<template>
    <v-container>
        <v-data-table
            :loading="!isDownloaded"
            :headers="headers"
            :items="tasks"
            class="elevation-2"
            :sort-desc="false"
            disable-pagination
            item-key="_id"
            hide-default-footer
        >
            <template v-slot:item.status="{ item }">
                <StatusChip :status="item.status" />
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                    <v-btn
                        color="green"
                        class="mt-1 mb-1 white--text"
                        @click.stop="changeRoute(item._id)"
                    >
                        Zobacz
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import StatusChip from "@/components/molecules/StatusChip.vue";
import io from "socket.io-client";

export default {
    components: {
        StatusChip,
    },
    computed: {
        id() {
            return this.$store.getters.getUserID;
        },
    },
    data() {
        return {
            socket: io(this.url),
            tasks: [],
            name: null,
            isDownloaded: false,
            headers: [
                {
                    text: "Zadanie",
                    align: "left",
                    sortable: true,
                    value: "title",
                },
                { text: "Status", value: "status", sortable: true },
                { text: "Akcje", value: "actions", sortable: false },
            ],
        };
    },
    methods: {
        getTask() {
            this.$axios
                .get(`${this.url}api/apprentice/${this.id}`)
                .then((resp) => {
                    this.tasks = resp.data.tasks;
                    this.name = resp.data.name;
                    this.isDownloaded = true;
                });
        },
        changeRoute(_id) {
            this.$router.push(`${this.id}/task/${_id}`);
        },
    },
    created() {
        this.getTask();

        this.socket.on("newTaskAprrenticeAdded", () => {
            this.getTask();
        });
        this.socket.on("newTaskAprrenticeEdited", () => {
            this.getTask();
        });
        this.socket.on("appreticeChangedStatus", () => {
            this.getTask();
        });
    },
};
</script>

<style></style>
